import { LogoutOutlined } from "@ant-design/icons";
import ProLayout, { ProBreadcrumb } from "@ant-design/pro-layout";
import { ProConfigProvider } from "@ant-design/pro-provider";
import { Button, ConfigProvider, Tooltip, theme } from "antd";
import { Footer } from "antd/es/layout/layout";
import { useEffect } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { logout, useIAMClientState } from "../iam-client";
import { treeRouter } from "../utils/common";
export const ProBasicLayout = () => {
  const { IAMClientAuthContext, dispatch } = useIAMClientState();
  const {
    token: { colorPrimaryBg },
  } = theme.useToken();
  const navigate = useNavigate();
  let menu;
  function renderIcon(menuId, iconName) {
    const emojiCodePattern = /^&#\d+;$/;

    if (emojiCodePattern.test(iconName)) {
      const emojiCharacter = String.fromCodePoint(
        parseInt(iconName.slice(2, -1), 10)
      );
      return (
        <span
          key={menuId}
          dangerouslySetInnerHTML={{ __html: emojiCharacter }}
        />
      );
    } else {
      // Handle other icon types if needed
      return null; // Return null if the icon is not valid
    }
  }

  useEffect(() => {
    getAllSubMenus();
  }, [IAMClientAuthContext]);

  const getAllSubMenus = () => {
    menu = IAMClientAuthContext.user ? IAMClientAuthContext.user : "";

    const menuData = IAMClientAuthContext.menuAccessObject
      ? IAMClientAuthContext.menuAccessObject
      : [];

    // const menus = menuData.sort((a, b) => a.orderId - b.orderId);
    const processedMenuData = menuData.map((menuItem) => {
      const menuItems = menuItem.subMenuData.sort(
        (a, b) => a.orderId - b.orderId
      );
      const processedSubMenuItems = menuItems?.map((subMenuItem) => ({
        path: subMenuItem.path,
        label: subMenuItem.subMenuName,
        key: subMenuItem.subMenuId,
      }));
      return {
        key: menuItem.menuId,
        label: menuItem.menuName,
        icon: renderIcon(menuItem.menuId, menuItem.menuIconName),
        path: menuItem.path ? menuItem.path : "/",
        children:
          processedSubMenuItems.length > 0 ? processedSubMenuItems : null,
      };
    });

    return processedMenuData;
  };

  const logOut = () => {
    localStorage.clear();
    logout(dispatch);
    navigate("/login", { replace: true });
  };

  return (
    <ConfigProvider
      locale={{ locale: "en-US" }}
      theme={{
        algorithm: theme.compactAlgorithm,
        token: {
          colorPrimary: "#1890ff",
        },
      }}
    >
      <ProConfigProvider>
        <ProLayout
          title={"RFID-tracX"}
          logo={
            <img
              src={require("../Images/schemax.png")}
              alt="SchemaX Logo"
              style={{ height: "40px" }}
            />
          }
          locale="en-US"
          siderWidth={240}
          colorPrimary="#29397d"
          breakpoint={false}
          selectedKeys={menu}
          // collapsed={collapsed}
          headerContentRender={(props) =>
            props.layout !== "side" && document.body.clientWidth > 1000 ? (
              <ProBreadcrumb />
            ) : undefined
          }
          layout={"mix"}
          token={{
            header: { colorBgHeader: "transparent" },
            sider: {
              colorBgMenuItemSelected: colorPrimaryBg,
              colorMenuBackground: "#bae9ef42",
            },
          }}
          route={{
            key: menu,
            path: "/",
            routes: treeRouter(getAllSubMenus()),
          }}
          location={
            {
              // pathname,
            }
          }
          avatarProps={{
            src: "https://static.vecteezy.com/system/resources/thumbnails/007/033/146/small/profile-icon-login-head-icon-vector.jpg",
            size: "small",
            title: (
              <div style={{ color: "brown" }}>
                <h3>
                  <b>{menu.userName}</b>
                </h3>
              </div>
            ),
          }}
          contentStyle={{ paddingBlock: "10px", paddingInline: "10px" }}
          actionsRender={(props) => {
            return [
              <Tooltip placement="bottom" title={"Sign Out"}>
                <Button
                  size="middle"
                  style={{
                    borderRadius: "5px",
                    backgroundColor: "blanchedalmond",
                  }}
                  icon={<LogoutOutlined onClick={logOut} />}
                ></Button>
              </Tooltip>,
            ];
          }}
          menuItemRender={(item, dom) => {
            return (
              <Link
                to={item?.path || "/"}
                onClick={(e) => {
                  navigate(item.path || "/");
                }}
              >
                {dom}
              </Link>
            );
          }}
        >
          <Outlet />
          <Footer style={{ textAlign: "center", background: "#f0f2f5" }}>
            ©2024 Design and Developed by SchemaX
          </Footer>
        </ProLayout>
      </ProConfigProvider>
    </ConfigProvider>
  );
};
export default ProBasicLayout;

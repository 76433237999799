import React, { useState, useEffect } from "react";
import { Table, Button, Form, Input, message } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import axios from "axios";
import { appSettings } from "../config";

interface Item {
  id: number;
  itemName: string;
  status: string;
}

const ItemForm: React.FC = () => {
  const [items, setItems] = useState<Item[]>([]);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [editingItem, setEditingItem] = useState<Item | null>(null);

  // Fetch items from the backend
  const fetchItems = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${appSettings.backend_api}/items/getallActiveItems`,
        {
          // Pass any required request body data here, if needed.
        }
      );

      // Extract the 'data' array from the response
      const itemsData = response.data.data;

      // Ensure 'itemsData' is an array before setting it
      setItems(Array.isArray(itemsData) ? itemsData : []);
    } catch (error) {
      message.error("Failed to fetch items");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchItems();
  }, []);

  // Handle form submission for creating or editing an item
  const onFinish = async (values: { itemName: string }) => {
    try {
      if (editingItem) {
        // Edit the item
        await axios.post(`${appSettings.backend_api}/items/updateItem/${editingItem.id}`, {
          itemName: values.itemName,
        });
        message.success("Item updated successfully");
      } else {
        // Create a new item
        await axios.post(`${appSettings.backend_api}/items/createItem`, {
          itemName: values.itemName,
        });
        message.success("Item created successfully");
      }
      form.resetFields();
      setEditingItem(null);
      fetchItems();
    } catch (error) {
      message.error("Failed to save the item");
    }
  };

  // Edit action
  const handleEdit = (item: Item) => {
    setEditingItem(item);
    form.setFieldsValue({ itemName: item.itemName });
  };

  // Delete action
  const handleDelete = async (itemId: number) => {
    try {
      const response = await axios.delete(
        `${appSettings.backend_api}/items/deleteItems/${itemId}`
      );
      if (response.data.status) {
        message.success("Item deleted successfully (set to InActive)");
        fetchItems(); // Refetch items after deletion
      } else {
        message.error("Failed to delete the item");
      }
    } catch (error) {
      message.error("Failed to delete the item");
    }
  };

  // Table columns definition
  const columns = [
    {
      title: "S.No",
      dataIndex: "id",
      key: "id",
      align: "center" as const,
      render: (text: string, record: Item, index: number) => {
        const recordIndex = items.findIndex((item) => item.id === record.id);
        return recordIndex + 1; // Return the correct index
      },
    },
    {
      title: "Item Name",
      dataIndex: "itemName",
      key: "itemName",
      align: "left" as const,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center" as const,
      render: (status: string) => (
        <span style={{ color: status === "Active" ? "green" : "red" }}>
          {status}
        </span>
      ),
    },
    {
      title: "Action",
      key: "action",
      align: "center" as const,
      render: (_: any, record: Item) => (
        <span>
          <Button onClick={() => handleEdit(record)} icon={<EditOutlined />} />
          <Button
            onClick={() => handleDelete(record.id)}
            icon={<DeleteOutlined />}
            style={{ marginLeft: 8 }}
          />
        </span>
      ),
    },
  ];

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "20px",
      }}
    >
      {/* Table Section */}
      <div style={{ width: "60%", marginRight: "20px" }}>
        <Table
          columns={columns}
          dataSource={items}
          rowKey="id"
          loading={loading}
          pagination={{ pageSize: 10 }}
        />
      </div>

      {/* Form Section */}
      <div style={{ width: "35%" }}>
        <h2>{editingItem ? "Edit Item" : "Add New Item"}</h2>
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item
            label="Item Name"
            name="itemName"
            rules={[{ required: true, message: "Please enter the item name" }]}
          >
            <Input placeholder="Enter item name" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {editingItem ? "Update" : "Create"}
            </Button>
            {editingItem && (
              <Button
                style={{ marginLeft: 8 }}
                onClick={() => {
                  form.resetFields();
                  setEditingItem(null);
                }}
              >
                Cancel
              </Button>
            )}
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default ItemForm;

import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Carousel,
  Col,
  Divider,
  Form,
  Input,
  Row,
  notification,
} from "antd";
import { Header } from "antd/es/layout/layout";
import Axios from "axios";
import React, { ChangeEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import downloadicon from "../../Images/Downlodeicon.png";
import logo2 from "../../Images/RFID-tracX White.png";
import pdf from "../../Images/RFID-tracX.jpg";
import supporticon from "../../Images/Support-icon.png";
import videoiconnew from "../../Images/Videoiconnew.png";
import WhatsappIcon from "../../Images/Whatsappicon.png";
import SchemaxLogoWhite1 from "../../Images/X Logo 2.png";
import { loginUser } from "../action";
import { useIAMClientState } from "../iam-client";
import { LoginUserDto } from "../user-models";
import "./LoginScreen.css";

const LoginScreen = () => {
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const navigate = useNavigate();

  const handleUsernameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const { IAMClientAuthContext, dispatch } = useIAMClientState();

  const handleSubmit = async (values) => {
    try {
      const req = new LoginUserDto(
        values.username,
        values.password,
        IAMClientAuthContext.authServerUrl
      );
      let response = await loginUser(dispatch, req);

      if (!response.user) {
        return false;
      } else {
        navigate("/dashboard", { replace: true });

        return true;
      }
    } catch (error: any) {
      notification.config({ maxCount: 3, duration: 3, placement: "top" });
      notification.destroy();
      notification.error({
        message: "Error",
        description: error.message,
      });
      return false;
    }
  };

  const ProductsCards = [
    {
      title: "Warehouse Management",
      image: pdf,
    },
  ];

  const contentStyle: React.CSSProperties = {
    height: "86vh",
    color: "#fff",
    textAlign: "center",
  };

  const downloadFile = async () => {
    try {
      const response = await Axios.get(
        "./assets/autoexpert-product-catalogy-ppt.pdf",
        { responseType: "blob" }
      );
      const blob = new Blob([response.data]);
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "autoexpert-product-catalogy-ppt.pdf";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Failed to download file:", error);
    }
  };

  return (
    <div style={{ height: "100vh", overflowY: "hidden" }}>
      <Header
        style={{
          padding: "20px",
          background: "black",
          // border: "1px solid gray",
          height: "37px",
        }}
      >
        <Row>
          <Col
            xs={8}
            sm={8}
            md={8}
            lg={8}
            xl={8}
            style={{ color: "white", marginTop: "-3px" }}
          >
            <img
              src={logo2}
              alt="rfid-tracX logo"
              style={{ height: "1.8rem" }}
            />
          </Col>
          <Col
            xs={8}
            sm={8}
            md={8}
            lg={8}
            xl={8}
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "-10px",
            }}
          >
            <img src={SchemaxLogoWhite1} alt="/" style={{ height: "57px" }} />
          </Col>
          <Col
            xs={8}
            sm={8}
            md={8}
            lg={8}
            xl={8}
            style={{ display: "flex", justifyContent: "end" }}
          >
            <a
              href="https://www.schemaxtech.com/"
              style={{
                background: "transparent",
                border: "none",
                cursor: "pointer",
              }}
            >
              <img
                src={supporticon}
                alt="supporticon"
                style={{
                  fontSize: "54px",
                  height: "41px",
                  marginLeft: "20px",
                }}
              />
            </a>

            <img
              src={videoiconnew}
              alt="Video icon"
              style={{
                fontSize: "24px",
                height: "35px",
                paddingBottom: "24px",
                marginLeft: "20px",
                cursor: "pointer",
                marginTop: "7px",
              }}
            />
            <img
              onClick={downloadFile}
              src={downloadicon}
              alt="/"
              style={{
                fontSize: "24px",
                height: "37px",
                paddingBottom: "24px",
                marginLeft: "20px",
                cursor: "pointer",
                marginTop: "7px",
              }}
            />
            <a
              aria-label="Chat on WhatsApp"
              target="_blank"
              rel="noreferrer"
              href="https://wa.me/919014375798?text=Hi%20there%21%20I%27m%20reaching%20out%20for%20some%20help.%20Can%20you%20assist%20me%20regarding%20RFID-tracX%3F"
            >
              <img
                alt="Chat on WhatsApp"
                src={WhatsappIcon}
                style={{
                  color: "white",
                  fontSize: "24px",
                  height: "44px",
                  paddingBottom: "24px",
                  marginLeft: "20px",
                  cursor: "pointer",
                }}
              />
            </a>
          </Col>
        </Row>
      </Header>
      <Row>
        <Col
          xs={{ span: 24, order: 2 }}
          sm={{ span: 24, order: 2 }}
          md={{ span: 16, order: 1 }}
          lg={{ span: 18, order: 1 }}
        >
          <Card
            style={{ background: "white", height: "100%", border: "none" }}
            bodyStyle={{ padding: "0px" }}
          >
            <Carousel
              arrows={true}
              className="login-c"
              autoplay
              slidesToShow={1}
              speed={500}
            >
              {ProductsCards.map((card, key) => (
                <div key={key}>
                  <p style={contentStyle}>
                    <div>
                      <div className="flip-container">
                        <div className="flipper">
                          <div className="front">
                            <div>
                              <img
                                style={{
                                  height: "92vh",
                                  width: "100%",
                                  top: "0",
                                  marginTop: "-20px",
                                }}
                                alt="image1"
                                className="mb-2"
                                src={card.image}
                              />
                            </div>
                          </div>
                          <div className="back">
                            <Col className="justify-center">
                              <h3>{card.title}</h3>
                              <p
                                className="description"
                                style={{ color: "black" }}
                              ></p>
                            </Col>
                          </div>
                        </div>
                      </div>
                    </div>
                  </p>
                </div>
              ))}
            </Carousel>
          </Card>
        </Col>
        <Col
          xs={{ span: 24, order: 1 }}
          sm={{ span: 24, order: 1 }}
          md={{ span: 8, order: 2 }}
          lg={{ span: 6, order: 2 }}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Card
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              fontFamily: "cursive",
              background: "black",
              boxShadow: "none",
              borderRadius: 0,
              border: "none",
            }}
          >
            <Card
              className="glass"
              style={{
                textAlign: "center",
                background: "black",
                fontFamily: "sans-serif",
                border: "none",
                width: "300px",
                height: "600px",
              }}
            >
              <Row
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "5.5rem",
                }}
              >
                <img
                  src={logo2}
                  alt="rfid-tracX logo"
                  style={{ height: "1.8rem", marginBottom: "50px" }}
                />
              </Row>
              <div className="logo">
                <Form
                  name="normal_login"
                  className="container"
                  initialValues={{ remember: true }}
                  onFinish={handleSubmit}
                >
                  <Col span={24}>
                    <Form.Item
                      name="username"
                      rules={[
                        {
                          required: true,
                          message: "Please input your Username!",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Username"
                        className="custom-placeholder"
                        value={username}
                        onChange={handleUsernameChange}
                        style={{
                          background: "#000",
                          border: "none",
                          width: "260px",
                          height: "40px",
                          color: "white",
                          boxShadow: "0 0 10px #822bff",
                        }}
                        autoComplete="new-username"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: "Please input your password!",
                        },
                      ]}
                    >
                      <Input.Password
                        className="custom-placeholder"
                        value={password}
                        onChange={handlePasswordChange}
                        placeholder="Password"
                        iconRender={(visible) =>
                          visible ? (
                            <EyeOutlined style={{ color: "white" }} />
                          ) : (
                            <EyeInvisibleOutlined style={{ color: "white" }} />
                          )
                        }
                        style={{
                          background: "#000",
                          border: "none",
                          width: "260px",
                          height: "40px",
                          color: "white",
                          boxShadow: "0 0 10px #822bff",
                        }}
                        autoComplete="new-password"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item>
                      <Button
                        htmlType="submit"
                        style={{
                          textSizeAdjust: "50",
                          backgroundColor: "#7a6bcc",
                          width: "260px",
                          color: "#FFFFFF",
                          height: "40px",
                          fontSize: "18px",
                          border: "black",
                          fontFamily: "calibri",
                          fontWeight: "bold",
                          boxShadow: "#A46BF5 -4px 15px 40px -8px",
                          letterSpacing: "1px",
                        }}
                      >
                        Login
                      </Button>
                    </Form.Item>
                  </Col>
                </Form>
              </div>
            </Card>
            <div
              style={{
                position: "absolute",
                bottom: "10px",
                width: "100%",
                left: "130px",
                color: "white",
                fontSize: "14px",
                fontFamily: "sans-serif",
                fontWeight: 500,
              }}
            >
              <Divider style={{ color: "#ffff" }} />
              <span>© {new Date().getFullYear()} Schemax Tech</span>
              {/* <span>
                © {new Date().getFullYear()} Schemax Export Techno Craft Pvt.
                Ltd
              </span> */}
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default LoginScreen;

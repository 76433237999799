import "./App.css";
import MasterRoutes from "./master-routes";

function App() {
  return (
    <div className="App">
      <MasterRoutes />
    </div>
  );
}

export default App;

import { IAMClientAuthActions } from "./reducers";
import { LoginUserDto, UserPermissionsDto } from "./user-models";
import { ActionTypes } from "./action-types";
import axios from "axios";

export const loginUser = async (
  dispatch: React.Dispatch<IAMClientAuthActions>,
  loginPayload: LoginUserDto
) => {
  const { authServerUrl, username, password } = loginPayload;
  const fileUploadPath = authServerUrl;

  try {
    dispatch({ type: ActionTypes.REQUEST_LOGIN });
    const response = await axios.post(
      `${authServerUrl}/authentications/login`,
      {
        username,
        password,
      }
    );
    const res = response.data;

    if (res.status) {
      const act: any = res.data;
      localStorage.setItem("token", act.accessToken);
      const menuData: UserPermissionsDto = act.accessMenuObj;
      const data = {
        loading: false,
        isAuthenticated: true,
        user: {
          userName: menuData.userName,
          profilePicPath: act?.["filesData"]?.[0]?.filePath?.slice(7)
            ? fileUploadPath + "/" + act?.["filesData"]?.[0]?.filePath?.slice(7)
            : null,
          roles: menuData.roleName.join(","),
          externalRefNo: menuData.externalRefNo,
        },
        defaultPlant: "SRPL",
        defaultPlantCurrency: "IDR",
        token: act.accessToken,
        menuAccessObject: menuData.menusData,
        errorMessage: "",
      };

      dispatch({ type: ActionTypes.LOGIN_SUCCESS, payload: data });
      localStorage.setItem("currentUser", JSON.stringify(data));

      return data;
    } else {
      throw Error(res.internalMessage);
    }
  } catch (error: any) {
    const errorMessage: any = { errorMessage: error.message };
    dispatch({ type: ActionTypes.LOGIN_ERROR, payload: errorMessage });
    throw Error(error.message);
  }
};
export const logout = async (
  dispatch: React.Dispatch<IAMClientAuthActions>
) => {
  dispatch({ type: ActionTypes.LOGOUT });
  localStorage.removeItem("currentUser");
  localStorage.removeItem("token");
};

import React, { useState, useEffect } from "react";
import { Table, Button, Form, Input, message } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import axios from "axios";
import { appSettings } from "../config";

interface WorkStation {
  id: number;
  wareHouseName: string;
  status: string;
}

const WorkStationForm: React.FC = () => {
  const [workStations, setWorkStations] = useState<WorkStation[]>([]);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [editingWorkStation, setEditingWorkingStation] =
    useState<WorkStation | null>(null);

  // Fetch items from the backend
  const fetchWorkStations = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${appSettings.backend_api}/warehouse/getAllActiveWareHouses`
      );
      // The response data is in the "data" property of the main response object
      const data = Array.isArray(response.data.data) ? response.data.data : []; // Ensure data is an array
      setWorkStations(data); // Set the "data" inside response.data
    } catch (error) {
      message.error("Failed to fetch work stations");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchWorkStations();
  }, []);

  // Handle form submission for creating or editing an item
  const onFinish = async (values: { wareHouseName: string }) => {
    try {
      if (editingWorkStation) {
        // Edit the item
        await axios.post(
          `${appSettings.backend_api}/warehouse/updateWareHouse/${editingWorkStation.id}`,
          { wareHouseName: values.wareHouseName }
        );
        message.success("Work Station updated successfully");
      } else {
        // Create a new item
        await axios.post(`${appSettings.backend_api}/warehouse/createWareHouse`, {
          wareHouseName: values.wareHouseName,
        });
        message.success("Work Station created successfully");
      }
      form.resetFields();
      setEditingWorkingStation(null);
      fetchWorkStations();
    } catch (error) {
      message.error("Failed to save the work station");
    }
  };

  // Edit action
  const handleEdit = (workStation: WorkStation) => {
    setEditingWorkingStation(workStation);
    form.setFieldsValue({ wareHouseName: workStation.wareHouseName });
  };

  // Delete action
  const handleDelete = async (warehouseId: number) => {
    try {
      await axios.delete(
        `${appSettings.backend_api}/warehouse/deleteWareHouse${warehouseId}`
      );
      message.success("Work Station deleted successfully");
      fetchWorkStations();
    } catch (error) {
      message.error("Failed to delete the work station");
    }
  };

  // Table columns definition
  const columns = [
    {
      title: "S.No",
      dataIndex: "id",
      key: "id",
      render: (text: string, record: WorkStation, index: number) => {
        const recordIndex = workStations.findIndex(
          (workStation) => workStation.id === record.id
        );
        return recordIndex + 1; // Return the correct index
      },
    },
    {
      title: "Work Station Name",
      dataIndex: "wareHouseName",
      key: "wareHouseName",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status: string) => (
        <span style={{ color: status === "Active" ? "green" : "red" }}>
          {status}
        </span>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_: any, record: WorkStation) => (
        <span>
          <Button onClick={() => handleEdit(record)} icon={<EditOutlined />} />
          <Button
            onClick={() => handleDelete(record.id)}
            icon={<DeleteOutlined />}
            style={{ marginLeft: 8 }}
          />
        </span>
      ),
    },
  ];

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "20px",
      }}
    >
      {/* Table Section */}
      <div style={{ width: "60%", marginRight: "20px" }}>
        <Table
          columns={columns}
          dataSource={workStations}
          rowKey="id"
          loading={loading}
          pagination={{ pageSize: 10 }}
        />
      </div>

      {/* Form Section */}
      <div style={{ width: "35%" }}>
        <h2>
          {editingWorkStation ? "Edit Work Station" : "Add New Work Station"}
        </h2>
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item
            label="Work Station Name"
            name="wareHouseName"
            rules={[
              { required: true, message: "Please enter the work station name" },
            ]}
          >
            <Input placeholder="Enter work station name" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {editingWorkStation ? "Update" : "Create"}
            </Button>
            {editingWorkStation && (
              <Button
                style={{ marginLeft: 8 }}
                onClick={() => {
                  form.resetFields();
                  setEditingWorkingStation(null);
                }}
              >
                Cancel
              </Button>
            )}
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default WorkStationForm;

import { StrictMode } from "react";
import * as ReactDOM from "react-dom/client";
import { ConfigProvider, theme } from "antd";
import enUS from "antd/es/calendar/locale/en_US";
import { Locale } from "antd/es/locale";
import { IAMClientProvider } from "./iam-client";
import App from "./App";
import { appSettings } from "./config";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const authServerUrl = appSettings.APP_IAM_SERVER_URL;
const clientId = appSettings.APP_IAM_SERVER_URL;

root.render(
  <StrictMode>
    {/* <BrowserRouter> */}
    <IAMClientProvider authServerUrl={authServerUrl} clientId={clientId}>
      <ConfigProvider
        locale={enUS as unknown as Locale}
        theme={{
          algorithm: theme.compactAlgorithm,
          token: { colorPrimary: "#29397d" },
        }}
      >
        <App />
      </ConfigProvider>
    </IAMClientProvider>
    {/* </BrowserRouter> */}
  </StrictMode>
);

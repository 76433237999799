import {
  createHashRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import ProBasicLayout from "./basic-layout/pro-layout";
import DashBoard from "./dashBoardComponents/DashBoard";
import ItemForm from "./Item/ItemForm";
import WorkStationForm from "./WorkStation/WorkStationForm";
import LoginScreen from "./iam-client/Login/LoginScreen";

export const MasterRoutes = () => {
  const router = createHashRouter(
    createRoutesFromElements(
      <Route >
        <Route path="/login" element={<LoginScreen />} />

        <Route
          path="/"
          key="/"
          element={
            <>
              <ProBasicLayout />
            </>
          }
        >
          <Route path="/dashboard" element={<DashBoard />} />
          <Route path="/items" element={<ItemForm />} />
          <Route path="/workstation" element={<WorkStationForm />} />
        </Route>
      </Route>
    )
  );
  return <RouterProvider router={router} />;
};

export default MasterRoutes;
